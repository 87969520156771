import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
    *,
    *::after,
    *::before {
        box-sizing: border-box;
    }
    ::-webkit-scrollbar {
        &-track {
            box-shadow: ${({ theme }) => `inset 0 0 5px ${theme.scrollThumbHover}`};
        }
        &-thumb {
            background: ${({ theme }) => theme.scrollThumb};
            &:hover {
                background: ${({ theme }) => theme.scrollThumbHover};
            }
        }
    }
    html {
        background-color: ${({ theme }) => theme.contentBackground};
    }
    body {
        background: ${({ theme }) => theme.contentBackground};
        color: ${({ theme }) => theme.primaryContentText};
        display: block;
        font-family: Arial;
        transition: all 0.25s linear;

        h2, h3, h4, h5, p {
            color: ${({ theme }) => theme.primaryContentText};
        }

        table.labelTextTable{
            tbody{
                tr.labelTextRow{
                    .tableLeftLabel, .tableRightText{
                        border-bottom-color: ${({ theme }) => theme.tableBorder};
                    }
                }
            }
        }
        table.responsiveTable {
            border: 1px solid ${({ theme }) => theme.tableBorder};
            thead {
                th {
                    background: ${({ theme }) => theme.tableHeaderColor};
                    border: 1px solid ${({ theme }) => theme.tableBorder};
                }
            }
            tbody {
                tr {
                    td {
                        border: 1px solid ${({ theme }) => theme.tableBorder};
                        color: ${({ theme }) => theme.tableTextColor};
                    }
                }
            }
            
            .link {
                color: ${({ theme }) => theme.tableTextColor};
            }
        }
        
        table.responsiveTable.striped-vertically {            
            tbody {
                tr td:nth-child(odd){
                    background: ${({ theme }) => theme.tableOddBackgroundColor};
                }
    
                tr td:nth-child(even){
                    background: ${({ theme }) => theme.tableEvenBackgroundColor};
                }
            }
        }
        
        table.responsiveTable.striped-horizontally {            
            tbody {
                tr:nth-child(odd){
                    background: ${({ theme }) => theme.tableOddBackgroundColor};
                }
    
                tr:nth-child(even){
                    background: ${({ theme }) => theme.tableEvenBackgroundColor};
                }
            }
        }
        
        .prevPageClickIcon {
            & svg {
                fill: ${({ theme }) => theme.prevPageClickIcon};
            }
        
        }
        
        select{
            border-color: ${({ theme }) => theme.fieldBorder} !important;
            background: ${({ theme }) => theme.dropdownBackground} !important;
            color: ${({ theme }) => theme.fieldText};
        }
        .loginContent {
            box-shadow: ${({ theme }) => `0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 ${theme.shadow}, 0 3px 1px -2px rgba(0,0,0,.2)`};
            border-color: ${({ theme }) => theme.loginBorder};
            background: ${({ theme }) => theme.loginContentBackground};
        }
        .popUp{
            color: ${({ theme }) => theme.popupText};
            background: ${({ theme }) => theme.popupBackground};
            box-shadow: ${({ theme }) => `0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 ${theme.shadow}, 0 3px 1px -2px rgba(0,0,0,.2)`};
        }
        
        .notification-messages {
            .message {
                border-bottom: 10px solid ${({ theme }) => theme.popupBackground};
            }
        }
        
        .card {
            box-shadow: ${({ theme }) => `0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 ${theme.shadow}, 0 3px 1px -2px rgba(0,0,0,.2)`};
            border-color: ${({ theme }) => theme.cardBorder};
            background: ${({ theme }) => theme.cardBackground};
        }
        .searchPhrases {
            .cardRow {
                .leftDiv {
                    border-color: ${({ theme }) => theme.info_layer_border} !important;
                }
                .infoLayer {
                    background-color: ${({ theme }) => theme.info_layer};
                }
            }
        }
        .accordionTitle{
            color: ${({ theme }) => theme.accordionTitleText};
        }
        .notChecked, .checked{
            border-color: ${({ theme }) => theme.checkboxBorder};
            color: ${({ theme }) => theme.checkboxTick};
            background: ${({ theme }) => theme.checkboxBackground};
        }
        input, textArea{
            border-color: ${({ theme }) => theme.fieldBorder};            
            background: ${({ theme }) => theme.fieldBackground};
            color: ${({ theme }) => theme.fieldText};
            ::placeholder { 
                color: ${({ theme }) => theme.fieldText};
            }
            filter: none;
            &:-webkit-autofill  {
                -webkit-text-fill-color: white !important;
                -webkit-box-shadow: 0 0 0 3rem ${({ theme }) => theme.fieldBackground} inset !important;
                background-clip: content-box !important;
                &:focus {
                    -webkit-box-shadow: 0 0 8px ${({ theme }) => theme.activeFieldBorder}, 0 0 0 3rem ${({ theme }) => theme.fieldBackground} inset !important;
                }
            }
            &:focus {
                border-color: ${({ theme }) => theme.activeFieldBorder};
                box-shadow: 0 0 8px ${({ theme }) => theme.activeFieldBorder}
            }
        }
        .button {
            color: ${({ theme }) => theme.defaultButtonText};
            background-image: -webkit-gradient(linear,left top,left bottom,from(hsla(0,0%,100%,.1)),to(hsla(0,0%,100%,0)));
            background-image: linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0));
        }
        .btn-default {
            border-color: ${({ theme }) => theme.defaultButtonBorder};
            background: ${({ theme }) => theme.defaultButtonBackground};
            box-shadow: ${({ theme }) => `0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 ${theme.shadow}, 0 3px 1px -2px rgba(0,0,0,.2)`};
        }
        .btn-primary{
            background-color: ${({ theme }) => theme.primaryButtonBackground};
            -webkit-box-shadow: inset 0 0 0 1px rgba(16,22,26,.4), inset 0 -1px 0 rgba(16,22,26,.2);
            box-shadow: inset 0 0 0 1px rgba(16,22,26,.4), inset 0 -1px 0 rgba(16,22,26,.2);
        }
        .btn-secondary{
            background-color: ${({ theme }) => theme.secondaryButtonBackground};
        }
        .btn-warning{
            background-color: ${({ theme }) => theme.warningButton};
        }
        .button:focus{
            outline-color: ${({ theme }) => theme.buttonFocus};
        }
        .autoComplete {
            label{
                color: ${({ theme }) => theme.primaryContentText};
            }
            input, textArea {
                border-color: ${({ theme }) => theme.fieldBorder};
                color: ${({ theme }) => theme.fieldText};
            }
            .md-divider {
                background: ${({ theme }) => theme.fieldBorder};
            }
        }
        .autoComplete-list {
            background: ${({ theme }) => theme.secondaryContentBackground};
            color: ${({ theme }) => theme.primaryContentText};
            box-shadow: ${({ theme }) => `0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 ${theme.shadow}, 0 3px 1px -2px rgba(0,0,0,.2)`};
            li{
                &:hover {
                    background:  ${({ theme }) => theme.secondaryContentText};
                    color:  ${({ theme }) => theme.menuBackground};
                }
            }
            .active{
                background:  ${({ theme }) => theme.secondaryContentText};
                color:  ${({ theme }) => theme.menuBackground};
            }
        }
        .link {
            color: ${({ theme }) => theme.linkTextColor};
            text-decoration-color: ${({ theme }) => theme.linkUnderlineColor};
            &:hover{
                color: ${({ theme }) => theme.linkHoverTextColor};
            }
        }
        .menuType {
            color: ${({ theme }) => theme.menuText};
            border-color: ${({ theme }) => theme.menuBorder};
            background: ${({ theme }) => theme.menuBackground};
        }
        .menuLinkType{
            color: ${({ theme }) => theme.menuText};
            border-color: ${({ theme }) => theme.menuBorder};
            background: ${({ theme }) => theme.menuBackground};
        }
        .essCard {
            .infoLayerBorder {
                border-color: ${({ theme }) => theme.info_layer_border} !important;
            }
            .versionText{
                color: ${({ theme }) => theme.cardVersionText};
            }
            .section {
                border-width: 3px !important;
                border-color: ${({ theme }) => theme.cardSectionBorder};
                .sectionTitle{
                    background: ${({ theme }) => theme.cardSectionTitleBackground};
                    color: ${({ theme }) => theme.cardSectionTitleText};
                }
                .sectionContent{
                    background-color: ${({ theme }) => theme.cardSectionContent};
                    color:${({ theme }) => theme.cardSectionContentText};
                    .generalProcessAction {
                        border-color: ${({ theme }) => theme.generalProcessAction} !important;
                    }
                    h3 {
                        color:${({ theme }) => theme.cardSectionContentText};
                    }
                }
            }
        }
        .activeNode{
            border-color: ${({ theme }) => theme.treeNodeBorder};
        }
        .activeOutline{
            border-color: ${({ theme }) => theme.treeNodeBorder};
            color: ${({ theme }) => theme.treeNodeBorder};
        }
        .closeIcon-card {
            svg {
                fill: ${({ theme }) => theme.closeButton};
            }
        }
        
        .close{
            svg {
                fill: ${({ theme }) => theme.closeIcon};
            }
        }
        .headerIcon{
            svg{
                fill: ${({ theme }) => theme.menuIcon};
            }
        }
        .sidenavItem:hover, .logout:hover{
            background: ${({ theme }) => theme.secondaryContentBackground};
        }
        .sidenavItem {
             .link:hover{
                color: ${({ theme }) => theme.menuText};
            }
        }
        .closeIcon-message{
            svg {
                fill: ${({ theme }) => theme.messageCloseIcon};
            }
        }
        .success{
            color: ${({ theme }) => theme.successText};
            background: ${({ theme }) => theme.successBackground};
            box-shadow: ${({ theme }) => `0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 ${theme.shadow}, 0 3px 1px -2px rgba(0,0,0,.2)`};
            svg {
                fill: ${({ theme }) => theme.successText};
            }
        }
        .error{
            color: ${({ theme }) => theme.errorText};
            background: ${({ theme }) => theme.errorBackground};
            box-shadow: ${({ theme }) => `0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 ${theme.shadow}, 0 3px 1px -2px rgba(0,0,0,.2)`};
            svg {
                fill: ${({ theme }) => theme.errorText};
            }
        }
        .warning{
            color: ${({ theme }) => theme.warningText};
            background: ${({ theme }) => theme.warningBackground};
            box-shadow: ${({ theme }) => `0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 ${theme.shadow}, 0 3px 1px -2px rgba(0,0,0,.2)`};
            svg {
                fill: ${({ theme }) => theme.warningText};
            }
        }
        .info{
            color: ${({ theme }) => theme.infoText};
            background: ${({ theme }) => theme.infoBackground};
            box-shadow: ${({ theme }) => `0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 ${theme.shadow}, 0 3px 1px -2px rgba(0,0,0,.2)`};
            svg {
                fill: ${({ theme }) => theme.infoText};
            }
        }
        .redIcon {
            fill: ${({ theme }) => theme.redIcon} !important;
        }
        .greenIcon {
            fill: ${({ theme }) => theme.greenIcon} !important;
        }
        .yellowIcon {
            fill: ${({ theme }) => theme.yellowIcon} !important;
        }
        .arrowIcon {
          svg {
            fill: ${({ theme }) => theme.arrowIcon} !important;
          }
        }
        .tabs {
            .tab {
                background: ${({ theme }) => theme.toolTipBackground};
                border-color: ${({ theme }) => theme.toolTipBackground} !important;
            }
            .activeTab{
                background: ${({ theme }) => theme.cardBackground};
            }
        }
        .toolTipFullText{
            color: ${({ theme }) => theme.toolTipText};
            background: ${({ theme }) => theme.toolTipBackground};
        }
        
        .accordionTitle {
            svg {
                fill: ${({ theme }) => theme.closeIcon};
            }
        }
        
        .exportDownload {
            background: ${({ theme }) => theme.secondaryContentBackground};
        }
        
        .stripe:nth-child(odd) {
            background-color: ${({ theme }) => theme.stripeDark};
        }
        
        .stripe:nth-child(even) {
            background-color: ${({ theme }) => theme.stripeLight};
        }
        
        .loginContainer {
            .loginContent {
                .rememberMe {
                    padding-left: 27px;
                }
            }
        }
    }
`;

export const HeaderContainer = styled.div`
    background: ${({ theme }) => theme.headerBackground};
    border-bottom: ${({ theme }) => `2px solid ${theme.headerBorder}`};

    color: ${({ theme }) => theme.headerText};
    svg {
        fill: ${({ theme }) => theme.headerText};
    }
    .link{
        color: ${({ theme }) => theme.headerLinkTextColor};
        text-decoration-color: ${({ theme }) => theme.headerLinkUnderlineColor};
    }
    .sidenav{
        background: ${({ theme }) => theme.sideBarBG};
    }
    .badge {
        background: ${({ theme }) => theme.badgeBackground};
        color: ${({ theme }) => theme.badgeText};
    }
    .menuIcon:hover, .active{
        background: ${({ theme }) => theme.fieldBackground};
        border-color: ${({ theme }) => theme.fieldBackground};
    }
`;

export const FooterContainer = styled.div`
    background: ${({ theme }) => theme.footerBackground};
    color: ${({ theme }) => theme.footerText};
`;

export const StyledTreeNode = styled.div`
    padding: 5px 8px;
    padding-left: ${props => props.level * 20}px;
    margin-top: 15px;
    width: fit-content;
`;
