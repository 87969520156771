import React from 'react';
import { withTranslation } from '../common/translation';
import { FooterContainer } from '../../style/theme';
import { PredicareLogo } from '../common/icon';
import { SIZE } from '../../constant/text';

const Footer = () => (
    <FooterContainer className="footer">
        <PredicareLogo />
        <div className={SIZE.XS}>We care for life</div>
    </FooterContainer>
    );

export default React.memo(withTranslation()(Footer));
